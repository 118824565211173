<template>
  <div
      class="swiper businessesMainCarousel w-full h-96 md:h-128"
  >
    <div class="swiper-wrapper">

      <div
          class="swiper-slide"
          v-for="banner in banners"
          :key="banner"
      >
        <NuxtImg
            :src="banner"
            alt=""
            class="w-full object-cover bg-bottom h-96 md:h-128"
        />
      </div>
    </div>
  </div>
</template>

<script setup>

import {Autoplay, EffectFade} from "swiper/modules";

const banners = [
  "/images/businesses/main_1.jpg",
  "/images/businesses/main_2.jpg",
  "/images/businesses/main_3.jpg",
  "/images/businesses/main_4.jpg",
  "/images/businesses/main_5.jpg",
  "/images/businesses/main_6.jpg",
];

const swiper = ref(null);
onMounted(() => {
  swiper.value = new useNuxtApp().$swiper('.businessesMainCarousel', {
    modules: [Autoplay, EffectFade],
    direction: 'horizontal',
    loop: true,
    autoplay: {
      delay: 5000,
    },
    slidesPerView: 1,
    effect:'fade',
  allowTouchMove: false
});
})
</script>

<style></style>
