<template>
  <div
      class="flex flex-col text-a-neutral bg-a-main-gray relative font-sans tracking-wide max-w-full"
  >
    <Transition name="fade">
      <BusinessesHomeNav />
    </Transition>
    <Transition name="fade">
      <MainSideMenuMobile class="flex md:hidden"/>
    </Transition>
    <Transition name="fade">
      <BusinessesNav v-show="showStickyNavBar == true" />
    </Transition>
    <div class="flex flex-col">
      <slot />
    </div>
    <Footer />
  </div>
</template>
<script setup lang="ts">
const showStickyNavBar =  ref(false);
function handleScroll() {
  useNuxtApp().getScrollVerticalPositionInPixels() > 545
      ? (this.showStickyNavBar = true)
      : (this.showStickyNavBar = false);
}

function hasReachedBottom() {
  useNuxtApp().hasReachedBottom();
}

onMounted(()=>{
  nextTick(function () {
    //  useNuxtApp().addScrollListener(this.handleScroll);
  });
})

onBeforeUnmount(()=>{
  //useNuxtApp().removeScrollListener(this.handleScroll);
})
</script>

<style scoped>
.fade-enter-active {
  transition: opacity 0.1s ease-in-out;
}
.fade-leave-active {
  transition: opacity 0.1s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
