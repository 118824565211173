<template>
  <div class="flex flex-row w-full bg-black bg-opacity-40 h-auto text-white">
    <div
        class="flex flex-row items-center justify-evenly w-full max-w-screen-xl mx-auto h-full"
    >
      <!-- Brand Starts -->
      <nuxt-link
          :to="{ name: 'businesses' }"
          class="flex flex-col w-1/12 pl-2 items-start justify-center cursor-pointer"
      >
        <NuxtImg src="/images/main/logo_inv.png" alt="" class="w-auto" />
        <p class="text-sm mx-auto font-semibold tracking-widest">Businesses</p>
      </nuxt-link>
      <!-- Brand Ends-->
      <!-- Buttons Section -->
      <div class="flex flex-row items-center justify-start w-6/12">
        <nuxt-link
            :to="{ name: 'index' }"
            class="flex flex-row items-baseline px-4 font-semibold text-white hover:text-a-primary focus:outline-none"
        >
          Home
        </nuxt-link>
      </div>
      <!-- Buttons Section Ends -->
      <!-- Right Extra Section -->
      <div class="flex flex-row-reverse w-5/12 items-center justify-start">
        <!-- User Dropdown -->
        <BaseUserDropdown v-if="isLoggedIn" />
        <!-- User Dropdown Ends -->
        <div class="flex flex-row items-center justify-center" v-else>
          <nuxt-link
              :to="{ name: 'login' }"
              class="mx-2 px-2 hover:text-a-primary font-semibold"
          >
            Login
          </nuxt-link>
          <span class="text-2xl">/</span>
          <nuxt-link
              :to="{ name: 'register' }"
              class="mx-2 px-2 hover:text-a-primary font-semibold"
          >
            Register
          </nuxt-link>
        </div>
        <BaseButton
            margin-classes="mx-2"
            primary-color-class="a-primary"
            hovered-text-color-class="a-neutral-darkest"
            text-size-class="text-sm"
            width-class="w-auto"
            text-color-class="hover:text-a-neutral-darkest"
            is-outlined
            @click.native="gotoCreateNewBusiness"
        >
          <Icon
              name="mdi:plus"
          />
          List Business
        </BaseButton>
      </div>
      <!-- Right Extra Section Ends -->
    </div>
  </div>
</template>

<script setup lang="ts">
import {useAuthManager} from "#imports";

function gotoCreateNewBusiness() {
  navigateTo({
    name: "dashboard-businesses-create-new",
  });
}

const isLoggedIn = computed(()=>{
  return useAuthManager().isLoggedIn.value;
})
</script>

<style></style>
